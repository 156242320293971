.sftp-tab-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.file-selection {
    display: flex;
    gap: 20px;
}

.side-selection {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.title-and-save {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.instructions {
    margin-bottom: 10px;
}

.regex-input {
    width: 100%;
    margin-bottom: 10px;
}

.drag-drop-section {
    padding: 20px;
    border: 2px dashed #aaa;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.file-select-link {
    color: blue;
    cursor: pointer;
}

.file-input {
    display: none;
}

.sftp-process-btn {
    padding: 10px 20px;
    background-color: white;
    color: #4CAF50; /* Green text */
    border: 2px solid #4CAF50; /* Green border */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.sftp-process-btn:disabled {
    background-color: #ccc;
    color: white;
    border: none;
    cursor: not-allowed;
}

.sftp-process-btn:not(:disabled):hover {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    transform: translateY(-3px); /* Move up slightly */
}

.files-list {
    list-style-type: none;
    padding: 0;
}

.file-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filename-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.delete-btn,
.duplicate-btn {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}

.delete-btn {
    background-color: #dc3545; /* Original red background */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: box-shadow 0.3s;
}

.delete-btn:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.duplicate-btn {
    background-color: #17a2b8; /* Original turquoise background */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: box-shadow 0.3s;
}

.duplicate-btn:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.save-btn {
    background-color: white;
    color: #4CAF50; /* Green text */
    border: 2px solid #4CAF50; /* Green border */
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: auto;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}
