/* Import Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Main container */
.stitch-container {
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Header */
.stitch-header {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Segmented control */
.segmented-control-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.segmented-control {
  display: inline-flex;
  border: 2px solid #4caf50;
  border-radius: 8px;
  overflow: hidden;
}

.segment {
  padding: 12px 40px;
  cursor: pointer;
  color: #4caf50;
  font-size: 16px;
  background-color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.segment.active {
  background-color: #4caf50;
  color: white;
}

.segment:hover:not(.active) {
  background-color: #f9f9f9;
}

/* File count input */
.stitch-file-slider-container {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-count-input {
  display: flex;
  align-items: center;
}

.stitch-file-count-input {
  width: 50px;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.file-count-input button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.file-count-input button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.file-count-input button:hover:not(:disabled) {
  background-color: #45a049;
}

/* Action buttons */
.stitch-actions {
  margin-bottom: 30px;
  text-align: center;
}

.stitch-process-button,
.clear-stitch-button,
.stitch-files-button,
.download-stitched-file-button,
.submit-stitch-button {
  background-color: #ffffff;
  color: #4caf50;
  padding: 12px 24px;
  cursor: pointer;
  border: 1px solid #4caf50;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  margin-right: 10px;
}

.stitch-process-button:hover,
.clear-stitch-button:hover,
.stitch-files-button:hover,
.download-stitched-file-button:hover,
.submit-stitch-button:hover {
  background-color: #4caf50;
  color: #ffffff;
}

.stitch-process-button:disabled,
.clear-stitch-button:disabled,
.stitch-files-button:disabled,
.download-stitched-file-button:disabled {
  background-color: #d3d3d3;
  color: #666666;
  cursor: not-allowed;
}

/* File dropzone */
.file-dropzone {
  border: 2px dashed #4caf50;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 14px;
  color: #4caf50;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.file-dropzone:hover {
  background-color: #4caf50;
  color: white;
}

.file-dropzone input {
  display: none;
}

/* Table styling */
.stitch-mapping-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stitch-mapping-table th,
.stitch-mapping-table td {
  border: 1px solid #4caf50;
  padding: 12px;
  text-align: left;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.stitch-mapping-table th {
  background-color: white;
  color: #4caf50;
  font-weight: bold;
}

/* Sleek Checkbox Styling */
.stitch-mapping-table input[type="checkbox"] {
  appearance: none;
  width: 22px;
  height: 22px;
  border: 2px solid #4caf50;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.stitch-mapping-table input[type="checkbox"]:checked {
  background-color: #4caf50;
  border-color: #4caf50;
}

.stitch-mapping-table input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 12px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(45deg);
  top: 2px;
  left: 5px;
}


/* Sleek Dropdown Styling with space for arrow */
.stitch-mapping-table select {
  appearance: none;
  background-color: #ffffff;
  border: 2px solid #4caf50;
  border-radius: 8px;
  padding: 8px 40px 8px 12px; /* Add extra padding-right for the arrow */
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 60%; /* Make the select element fill the container width */
}

.stitch-mapping-table select:focus {
  outline: none;
  border-color: #45a049;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.3); /* Green glow */
}

.stitch-mapping-table select:hover {
  border-color: #45a049;
}

/* Add a custom arrow to the dropdown */
.stitch-mapping-table select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%234caf50'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 16px 16px;
}

/* Option padding for better spacing */
.stitch-mapping-table select option {
  padding: 10px; /* Add extra space for each option */
}

