.previous-breaks-container {
    padding: 20px;
    background-color: #ffffff; /* Lighter background */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    border: 1px solid #e0e0e0; /* Subtle border */
}

.previous-breaks-container h2 {
    color: #4CAF50; /* Green color for the heading */
    text-align: center;
    margin-bottom: 20px;
}

.previous-breaks-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #4CAF50; /* Green border */
    background-color: #f9f9f9; /* Slightly off-white background */
}

.previous-breaks-download-link {
    display: inline-block;
    background-color: #FFC107; /* Yellow color for the button */
    color: black; /* Black text for better contrast */
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 10px;
    font-weight: bold; /* Bold text */
}

.previous-breaks-download-link:hover {
    background-color: #FFB300; /* Darker yellow on hover */
}

.results-rec-page {
    margin-top: 20px;
    padding: 20px;
    background-color: #f2f2f2; /* Light grey background for content */
    border-radius: 4px;
}
