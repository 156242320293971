/* Full page styling */
body, html {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #202125;
}

/* Center content on page */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
}

/* Logo and software name styles */
.logo {
    width: auto;  /* Maintain the original aspect ratio */
    max-width: 250px;  /* Increase this value to make the logo larger */
    max-height: 250px;
    height: auto; /* This will automatically adjust based on the width */
    margin-bottom: 20px; /* Adjust margin if needed */
}

.auth-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

/* Input fields styling */
.auth-input {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.auth-input:focus {
    border-color: #007BFF;
    outline: none;
}



/* Button group styling */
.button-group {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.sso-button, .auth-button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

/* SSO button styling */
.sso-button {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
}

.sso-button:hover {
    background-color: #000000;
    color: #ffffff;
}

/* Login button styling */
.auth-button {
    background-color: #ffffff;
    color: #07ad15;
    border: 1px solid #2dad0d;
}

.auth-button:hover {
    background-color: #008a35;
    color: #ffffff;
}


