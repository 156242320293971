
  /* CSS for the container in ReconciliationPage */
  .recon-metrics-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  /* CSS for the ReconciliationMetricsDisplay and its children */
  .recon-metrics-display {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: 'Roboto', sans-serif;
  }
  
  .recon-metric-column {
    flex: 1;
    margin: 0 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    border-radius: 5px;
    max-height: 275px;
    overflow-y: auto;
  }
  
  .recon-metric-title {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #333;
  }
  
  .recon-metric-content {
    font-size: 15px;
  }
  
  .recon-metric-content p {
    margin: 10px 0;
    font-weight: 400;
  }