.config-form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 20px auto;
  font-family: 'Roboto', sans-serif;
}

.config-form-container h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.config-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-group label {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-bottom: 5px;
}

.input-group input[type="text"],
.input-group select,
.input-group input[type="file"] {
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
  background-color: #fdfdfd;
}

.input-group input[type="text"]:focus,
.input-group select:focus,
.input-group input[type="file"]:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  outline: none;
}

.input-group input[type="file"]::file-selector-button {
  background-color: #f2f2f2; /* Light gray background */
  color: #333; /* Dark text */
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #ccc; /* Light gray border */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.input-group input[type="file"]::file-selector-button:hover {
  background-color: #e6e6e6; /* Slightly darker gray on hover */
  border-color: #bbb; /* Darken border on hover */
}


.error {
  color: #ff0000;
  font-size: 14px;
}

.file-processing-section {
  display: flex;
  align-items: center; /* Center-align button and file inputs */
  gap: 10px;
  margin-top: 15px;
}

.file-processing-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px; /* Increases margin to move button down */
}

.file-processing-section button {
  width: 200px;
  height: 65px;
  font-size: 16px;
  text-align: center;
  background-color: #fcfafa; /* Light gray background */
  color: #333; /* Dark gray text */
  border-radius: 5px;
  border: 1px solid #ccc; /* Light gray border */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 35px; /* Moves the button down */
}

.file-processing-section button:hover {
  background-color: #e6e6e6; /* Slightly darker gray on hover */
  color: #333; /* Maintains dark gray text on hover */
}


.button, .submit-btn {
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.3s;
  margin-left: 5px;
}

.button:hover, .submit-btn:hover {
  background-color: #fafafa;
}

.add-btn {
  background-color: #ffffff;
}

.box {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
}

.input-row {
  display: flex;
  gap: 10px;
}

.btn-box {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.btn-box button {
  height: 40px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-sizing: border-box;
  margin: 0;
  background-color: #ffffff;
}

.back-to-home-btn {
  color: #000000;
  font-weight: bold;
  text-decoration: underline;
}

.back-to-home-btn:hover {
  text-decoration: underline;
  color: #2a2a2a;
}
