/* EditConfigForm Styles */
.edit-config-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative; /* For positioning the header */
}

.edit-config-form .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.edit-config-form h2 {
    color: #333;
    font-size: 1.5em;
}

.edit-config-form label {
    display: block;
    margin-bottom: 10px;
    color: #666;
    font-size: 1em;
}

.edit-config-form input[type="text"], .edit-config-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.edit-config-form input[type="checkbox"] {
    margin-right: 10px;
}

.edit-config-form .form-actions {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
}

.edit-config-form .save-edits-btn, .edit-config-form .cancel-btn {
    background-color: #FFC107;
    color: black;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px; /* Ensure the font-size is consistent */
    line-height: 1.5; /* Ensure the line-height is consistent */
}

.edit-config-form .save-edits-btn:hover, .edit-config-form .cancel-btn:hover {
    background-color: #FFB300;
}

.edit-config-form .remove-btn, .edit-config-form .add-btn {
    background-color: #f2f2f2; /* Lighter color for minimalism */
    color: #333;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal; /* Less bold */
    margin-top: 10px;
    margin-right: 5px; /* Horizontal space */
}

.edit-config-form .remove-btn:hover, .edit-config-form .add-btn:hover {
    background-color: #e6e6e6; /* Slightly darker on hover */
}

.edit-config-form .column-group {
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
    margin-top: 10px;
}

.edit-config-form .form-group {
    margin-bottom: 20px;
}
