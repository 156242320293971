/* Main Container */
.recent-results-table {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(135deg, #f5f5f5, #e6e6e6); /* subtle gradient */
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 20px 0;
    padding: 0;
    overflow-x: auto;
}
  
.timestamp-heading {
    color: #000000;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    text-align: center;
    padding: 10px 0; /* added padding */
}

.metric-heading {
  font-size: 20px; /* Slightly larger font size for the metric message for emphasis */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #e07676; /* A blue color for importance, but not alarming */
  border: 2px solid #e07676; /* Adds a vertical bar to the left for emphasis */
  padding-top: 10px; /* Space between the border and text */
  padding-bottom: 10px; /* Space between the border and text */
  background-color: #fff;
  text-align: center;
}


.search-break-type-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 15px; /* added padding */
}

.search-input, .break-type-dropdown {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1; /* ensures equal height */
    margin-right: 10px;
    height: 40px; /* uniform height */
}





.break-data-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #FAFAFA;
  border-spacing: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* subtle shadow for sleekness */
}

.break-data-table th, .break-data-table td {
  padding: 10px; /* reduced padding for a more compact look */
  border-bottom: 1px solid #ddd; /* subtle border for separation */
  text-align: left; /* aligns text to the left */
  font-size: 16px; /* slightly smaller font for sleekness */
}

.break-data-table thead th {
  background-color: #333;
  color: #fff;
  text-shadow: none; /* removed shadow for a cleaner look */
  font-size: 16px; /* slightly smaller font size for headers */
  padding: 10px; /* consistent padding */
}

/* Consistent Background for Status Column */
.break-data-table tbody tr[class^="break-pair-"] td[rowspan],
.break-data-table tbody tr[class^="break-pair-"]:hover td[rowspan] {
    background-color: #ffffff; /* Or the background color of the status column */
}





/* Targeting the last header for custom centering */
.break-data-table thead th:last-child {
  text-align: center;
  padding-left: 0px; /* Adjust this value as needed */
}

.break-data-table .status-info-container {
  display: flex;
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Initially centers content horizontally */
  padding-left: 0px; /* Aligns with the header */
}


/* Status Column Background */
.break-data-table tbody tr td[colspan] {
  background-color: #FAFAFA;
}

.break-data-table tbody tr[class^="break-pair-"]:hover td[colspan] {
  background-color: #FAFAFA;
}




.info-card {
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 10px 0;
    transition: box-shadow 0.3s ease; /* smooth transition */
}

.info-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* hover effect */
}

.break-data-table td span[contenteditable="true"] {
    border: 1px solid #eaeaea;
    padding: 5px;
    border-radius: 3px;
    display: block;
    min-height: 20px;
    background: #f9f9f9;
    margin-top: 5px;
}

.break-data-table td span[contenteditable="true"]:focus {
    outline: 2px solid #4caf50;
    background: #fff;
    transition: background-color 0.3s ease; /* smooth transition */
}



.status-row-unresolved,
.status-row-in-progress,
.status-row-resolved {
    background-color: #ffffff; /* unified background color */
}


.status-row td {
    position: relative;   /* Make it a relative container for absolute positioning of the dropdown button */
}

.recenttable-dropdown-button {
  position: absolute; /* Absolutely position the button */
  right: 10px; /* Place it 10px away from the right edge of the td */
  top: 50%; /* Center it vertically */
  transform: translateY(-50%); /* Adjust for perfect centering */
  background-color: #343534; /* Green background */
  color: white; /* White text */
  padding: 8px 16px; /* Padding for size */
  border: none; /* Remove border */
  cursor: pointer; /* Cursor to indicate clickable */
  border-radius: 5px; /* Rounded corners */
  font-size: 10px; /* Font size */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effect */
}

.recenttable-dropdown-button:hover {
  background-color: #3e493e; /* Darker shade of green */
}

/* Default non-hover background for regular rows for transition smoothness */
.break-data-table tr:not(.status-row) {
    background-color: #ffffff; 
    transition: background-color 0.3s; /* Optional smooth transition */
}


.status-info-container {
    display: flex;
    align-items: center;
  }
  
  .status-dropdown {
    margin-right: 10px;
  }
  
  .info-button {
    padding: 5px 10px;
  }

  .status-info-container {
    display: flex;
    align-items: center;
    gap: 10px; /* creates space between items */
  }
  
  /* Dropdown styling */
  .status-dropdown {
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
    background-color: #fff;
  }
  

  .info-icon {
    font-size: 26px;
    cursor: pointer;
    transition: color 0.3s, transform 0.3s, box-shadow 0.3s; /* Added transform transition */
}



.status-dropdown:hover {
  box-shadow: 0px 2px 8px rgba(35, 36, 36, 0.6);
}
  .status-dropdown {
    padding: 5px;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
  }

  

/* Break Data Container */
.break-data-container {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.action-buttons-container {
    display: flex;
    justify-content: flex-start; 
    margin-bottom: 20px; 
    align-items: center; 
}

/* Updated styles with the original green color scheme for .run-reconciliation-btn and .file-upload-btn */

.run-reconciliation-btn, .file-upload-btn {
  background-color: #ffffff;
  color: #07ad15;  /* Original green for text */
  border: 1px solid #07ad15;  /* Match border to the green */
  padding: 10px 18px;
  border-radius: 6px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Subtle shadow for depth */
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.run-reconciliation-btn:hover, .file-upload-btn:hover {
  background-color: #07ad15;  /* Green background on hover */
  color: #ffffff;  /* White text for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);  /* Slightly larger shadow on hover */
  transform: translateY(-1px);  /* Lift effect */
}

.run-reconciliation-btn:active, .file-upload-btn:active {
  background-color: #058d11;  /* Slightly darker green for active effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Smaller shadow on active */
  transform: translateY(1px);  /* Depression effect on click */
}

/* Add spacing if buttons are side by side */
.file-upload-btn {
  margin-left: 10px;
}


.download-excel-btn {
    
    margin-right: 10px; /* Spacing between the buttons */
    background-color: #ffffff;
    color: #07ad15;
    text-decoration: none;
    border: 1px solid #2dad0d;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.download-excel-btn:hover {
    background-color: #008a35;
    color: #fff;
}


.reload-btn{
  margin-left: auto; /* This will push it to the right */
  margin-right: 10px; /* Spacing between the buttons */
  background-color: #ffffff;
  color: #07ad15;
  text-decoration: none;
  border: 1px solid #2dad0d;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.reload-btn:hover {
  background-color: #008a35;
  color: #fff;
}


.save-changes-btn {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.save-changes-btn:hover {
    background-color: #45a049;
}

.save-changes-btn-active {
    background-color: #ffffff;
    color: #07ad15;
    text-decoration: none;
    border: 1px solid #2dad0d;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.save-changes-btn-active:hover {
    background-color: #008a35;
    color: #fff;
}






/* Disabled button styling */
.dropdown-button:disabled {
  background-color: #ccc; /* Gray background for disabled state */
  color: #666; /* Dark gray text color for disabled state */
  cursor: not-allowed; /* Cursor to indicate disabled */
}

/* Icon styling if you're using icons within buttons */
.dropdown-button .fa, /* Adjust if you're using FontAwesome */
.dropdown-button .fas { /* Adjust if you're using FontAwesome */
  margin-right: 8px; /* Space between icon and text */
}

.recenttable-dropdown-button {
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, background-color 0.3s, transform 0.3s;
  background-color: #fff;
  border: 2px solid #ccc;
  cursor: pointer;
  color: #333; /* Adjusted color for consistency */
}

.recenttable-dropdown-button:hover {
  background-color: #f1f1f1; /* Subtle hover effect */
  box-shadow: 0px 2px 8px rgba(35, 36, 36, 0.6);
}

.recenttable-dropdown-button:focus {
  border-color: #4caf50; /* Focus border color for consistency */
}

.original-entries-toggle {
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, background-color 0.3s, transform 0.3s;
  background-color: #fff;
  border: 2px solid #ccc;
  cursor: pointer;
  color: #333; /* Adjusted color for consistency */
  margin-left: 10px; /* Add some spacing to the left of the button */
}

.original-entries-toggle:hover {
  background-color: #f1f1f1; /* Subtle hover effect */
  box-shadow: 0px 2px 8px rgba(35, 36, 36, 0.6);
}

.original-entries-toggle:focus {
  border-color: #4caf50; /* Focus border color for consistency */
}


.info-card {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-top: 10px;
}
