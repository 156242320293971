.files-tab-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.file-selection {
    display: flex;
    justify-content: space-between;
}

.side-selection {
    width: 48%;
}

.sideA, .sideB {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.sideA > .title-and-save, .sideB > .title-and-save {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd; /* Subtle underline */
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.title-and-save::after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: #ddd; /* Subtle vertical line */
    margin-left: 10px;
}

.drag-drop-section {
    border: 2px dashed #4CAF50;
    padding: 20px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 4px;
}

.file-select-link {
    color: #6bdd67;
    text-decoration: underline;
    cursor: pointer;
}

.file-input {
    display: none;
}

.upload-btn {
    background-color: white;
    color: #4CAF50; /* Green text */
    border: 2px solid #4CAF50; /* Green border */
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.upload-btn:hover {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
}

.files-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
}

.sideA > h3, .sideB > h3 {
    margin-top: 10px; /* Space below the heading */
}

.sideA > .upload-section, .sideB > .upload-section {
    margin-top: 20px; /* Space between dropdown and upload section */
}

.upload-section .drag-drop-section {
    margin-bottom: 20px; /* Space between drag-drop section and file input list */
}

.file-date {
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
}

.save-btn {
    background-color: white;
    color: #4CAF50; /* Green text */
    border: 2px solid #4CAF50; /* Green border */
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: auto;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.save-btn:hover {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 8px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, transform 0.3s;
}

.file-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

.file-name {
    flex-grow: 1;
    font-size: 16px;
    color: #333;
    word-break: break-word;
}

.drag-drop-section {
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.drag-drop-section:hover {
    background-color: #f1f1f1;
    border-color: #aaa;
}
