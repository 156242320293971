.recon-parent-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  
  .recon-tab-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    gap: 10px;
  }
  
  .recon-tab {
    padding: 10px 20px;
    border: none;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .recon-tab-active {
    background-color: #ddd;
  }
  
  .config-name {
    text-align: center;
    font-size: 1.5em;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #333;
}



  
.reconciliation-page {
    font-family: Arial, sans-serif;
    background-color: #f3f4f6;
    padding: 20px;
}


.config-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.actions {
    display: flex;
    gap: 10px;
}

.send-to-lambda-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.send-to-lambda-button:hover{
    background-color: #0056b3;
}

.home-link {
    text-decoration: none;
    color: #007BFF;
    padding: 10px 20px;
    border: 1px solid #007BFF;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.home-link:hover {
    background-color: #007BFF;
    color: #fff;
}

.file-status-section {
    background-color: #fff;
    border: 1px solid #e1e4e8;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.file-status-content {
    display: flex;
    gap: 20px;
}

.recon-content {
    margin-top: 20px;
}

.recon-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
}

.recent-results {
    background-color: #fff;
    border: 1px solid #e1e4e8;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}


.previous-results {
    margin-top: 20px;
}

.config-data {
    background-color: #fff;
    border: 1px solid #e1e4e8;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}



.config-data-column-item {
    margin-top: 10px;
    padding-left: 20px;
}

.edit-config-button {
    margin-top: 10px;
}

.edit-config-form {
    background-color: #fff;
    border: 1px solid #e1e4e8;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}

/* Root class for the columns section */
.config-data-columns {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
}

.config-data-columns h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
    background-color: #f1f1f1;
    padding: 10px;
}

/* Styling each column item as a table row */
.config-data-column-item {
    display: table-row;
    background-color: #ffffff;
}

.config-data-column-item:hover {
    background-color: #f9f9f9;
}

/* Styling the title of each column item as a table cell */
.config-data-column-item h4 {
    display: table-cell;
    font-size: 1.2em;
    color: #444;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
}

/* Styling the details of each column item as table cells */
.config-data-column-item p {
    display: table-cell;
    color: #666;
    font-size: 1em;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
}

.custom-legend .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.custom-legend .color-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff; /* White background */
    color: #4caf50; /* Green text */
    font-size: 1.5em;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .ai-animation {
    width: 100px;
    height: 100px;
    position: relative;
    margin-right: 20px;
  }
  
  .circle {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 5px solid transparent;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  
  .circle-1 {
    border-top-color: #4caf50; /* Green color */
    border-bottom-color: #ffeb3b; /* Yellow color */
  }
  
  .circle-2 {
    border-left-color: #4caf50; /* Green color */
    border-right-color: #ffeb3b; /* Yellow color */
    animation-delay: -1s;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .no-results-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .send-button, .home-button {
    background-color: #ffffff; /* Green background */
    color: #4caf50;
    border: 1px solid ;
    padding: 10px 20px;
    margin-left: 10px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .send-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .send-button:hover:not(:disabled), .home-button:hover {
    background-color: #4caf50;
    color: #ffffff;
    border: 1px solid #4caf50;
  }
  
  .no-results-message {
    text-align: center;
    color: #555;
    font-size: 18px;
  }
  
  .page-refresh-button {
    background-color: #ffffff; /* Blue background */
    border: 1px solid ;
    color: #4caf50;
    padding: 10px 20px;
    
    text-decoration: none;
    font-size: 16px;
    margin-left: 10px; /* Add some space between the Refresh button and the previous element */
    cursor: pointer;
    border-radius: 4px;
    transition-duration: 0.3s;
  }
  
  .page-refresh-button:hover {
    background-color: #4caf50;
    color: #ffffff;
    
  }
  
  

  .no-results-message {
    
    flex-direction: column;
    align-items: center;
  
    height: 100vh; /* Full viewport height */
    text-align: center;
    font-family: 'Arial', sans-serif; /* An example of a light, easy-going font */
    color: #333; /* Soft color for text */
    padding: 20px;
   
  }
  
  .no-results-message h2,
  .no-results-message h3 {
    margin: 0 0 20px; /* Adds spacing between text elements */
    padding: 0;
    line-height: 1.4;
  }
  
  .no-results-message h2 {
    font-size: 24px; /* Adjust as needed */
    color: #555; /* Slightly darker for emphasis */
  }
  
  .no-results-message h3 {
    font-size: 20px;
    color: #777; /* Lighter for secondary information */
  }
  


  


  .config-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .config-dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    border: 0px  rgb(255, 255, 255);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .config-dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: rgb(252, 252, 252);
  }
  
 
  
  .config-dropdown-item:hover {
    background-color: #f1f1f1;
  }
  
  .config-dropdown-item.recon-tab-active {
    background-color: #ddd;
    color: white;
  }

  .chart-and-stats {
    display: flex;
    align-items: center;
  }
  
  .matched-chart-stats {
    margin-left: 20px;
  }
  
  .recon-metric-content {
    flex-shrink: 0; /* Prevent the chart from shrinking */
  }
  
  .status-chart-container {
    margin-left: 30px; /* Adjust as needed */
  }
  