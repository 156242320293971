
.bm-menu-wrap {
    top: 0;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
    margin-right: 10px;
}

h1 {
    color: #333333;
    font-size: 1.5rem;
}

.menu-item {
    padding: 10px;
    color: #333333;
    text-decoration: none;
}

.bm-menu {
    background: #ffffff; /* Subtle gradient background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds depth */
    font-size: 1.15em;
    
}

.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 0px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-cross-button {
    height: 30px;
    width: 36px;
}

.bm-cross {
    background: #000000;
    height: 30px;
    width: 30px;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: block; /* Ensures full width for touch targets */
    text-decoration: none;
    margin-bottom: 10px;
    color: #070706;
    transition: color 0.2s, background-color 0.2s; /* Smooth transition for hover */
    padding: 10px 15px; /* Increased padding for better touch targets */
    border-radius: 5px; /* Rounded corners */
}

.bm-item:hover {
    color: #3d3d3d; /* Subtle color change */
    text-decoration: none; /* No underline on hover */
    background-color: #fafafa; /* Very subtle background color change */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
}

.sign-out-link:hover {
    color: #3d3d3d; /* Subtle color change */
    background-color: #f0f0f0; /* Very subtle background color change */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
}


.sign-out-link {
    cursor: pointer; /* Makes it clear it's clickable */
    /* Add any specific styles for the sign-out link here */
}
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}



.tab-container {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: #f5f5f5;
    border-top: 3px solid transparent;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    font-size: 1rem;
    transition: background-color 0.2s, border-top-color 0.2s;
}

.tab:hover {
    background-color: #e0e0e0;
}

.tab:first-child {
    border-left: none;
}

.tab:last-child {
    border-right: none;
}

.tab:active, .tab:focus {
    outline: none;
    border-top-color: #007BFF; /* Change this to any color you prefer for the active tab */
    background-color: #fff;
}

.tab-active {
    border-top-color: #007BFF; 
    background-color: #fff;
}
.content-container {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; 
}

.user-info-container {
    cursor: pointer;
    margin-left: auto;
}

.user-info {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 50px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    background-color: #ffffff;
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: 'Roboto', sans-serif;
    
}

.user-info:hover {
    background-color: #ffffff;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.user-icon {
    margin-right: 10px;
    font-size: 1.2rem;
    color: #000000;
}


.sign-out-button {
    position: absolute;
    top: 60px;
    right: 95px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    opacity: 0;
    transform: translateY(-10%);
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 0;
    pointer-events: none;
  
 
}

.sign-out-button-visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto; 
}


.sign-out-icon {
    margin-right: 5px;
    font-size: 1.2rem;
}

/* Ensure styles are scoped to header component to avoid conflicts */

/* Header Container */
.header-container {
    position: relative;
    width: 100%;
}

/* Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logo Container */
.header-logo-container {
    display: flex;
    align-items: center;
}

/* Logo */
.header-logo {
    height: 40px;
    margin-right: 10px;
}

/* Logo Text */
.header-logo-text {
    color: #333333;
    font-size: 2.5rem;
    
}

/* Beta Tag */
.header-beta-tag {
    background-color: #48b45c;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 2px 8px;
    margin-left: 10px;
    
    border-radius: 4px;
}

/* User Info Container */
.header-user-info-container {
    cursor: pointer;
    margin-left: auto;
}

/* User Info */
.header-user-info {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 50px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    background-color: #ffffff;
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: 'Roboto', sans-serif;
}

.header-user-info:hover {
    background-color: #ffffff;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* User Icon */
.header-user-icon {
    margin-right: 10px;
    font-size: 1.2rem;
    color: #000000;
}

/* Sign Out Button */
.header-sign-out-button {
    position: absolute;
    top: 60px;
    right: 95px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    opacity: 0;
    transform: translateY(-10%);
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 0;
    pointer-events: none;
}

.header-sign-out-button-visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

/* Sign Out Icon */
.header-sign-out-icon {
    margin-right: 5px;
    font-size: 1.2rem;
}

/* Burger Menu Custom Styles */
.bm-menu {
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1.15em;
}

.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 0px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-cross-button {
    height: 30px;
    width: 36px;
}

.bm-cross {
    background: #000000;
    height: 30px;
    width: 30px;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #070706;
    transition: color 0.2s, background-color 0.2s;
    padding: 10px 15px;
    border-radius: 5px;
}

.bm-item:hover {
    color: #3d3d3d;
    text-decoration: none;
    background-color: #fafafa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-sign-out-link:hover {
    color: #3d3d3d;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
