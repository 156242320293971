body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

.active {
  background-color: #ddd;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}




/* New Combined Container */
.configs-section {
  background-color: rgb(255, 255, 255);
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px;
  margin: 10px 0; /* vertical spacing */
}

.config-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  position: relative;
}

.config-search-container select {
  padding: 8px 12px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.config-search-section {
  flex: 1;
}

.config-search-input {
  width: 100%; /* Reduced width */
  padding: 8px 12px; /* Padding for comfort */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: box-shadow 0.3s, transform 0.3s; /* Smooth transition */
}

.config-search-input:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Slightly pronounced shadow when focused */
  transform: scale(1.02); /* Slight growth for feedback */
  outline: none; /* Remove the browser default outline */
}

.config-create-section {
  margin-left: 20px;
}
.create-config-button {
  background: linear-gradient(45deg, #4caf50, #81c784); /* Green gradient */
  color: #fff; /* White text */
  padding: 8px 16px; /* Spacing */
  border: none; /* Remove any default borders */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer; /* Hand cursor */
  transition: box-shadow 0.3s, transform 0.3s; /* Smooth transition */
}

.create-config-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly pronounced shadow on hover */
  transform: translateY(-2px); /* Slight upward motion for feedback */
}

.create-config-button:active {
  transform: translateY(0); /* Reset position when active/clicked */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Reset shadow when active/clicked */
}

.config-card-status {
  font-size: 12px;
  color: #666;  /* Adjust color as needed */
  margin-top: 4px;  /* Adjust spacing as needed */
}


.configs-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto; 
}

.home-config-card {
  flex: 0 0 calc(50% - 10px); 
  margin-bottom: 10px;
}

.refresh-button {
  width: 40px; /* adjust size as needed */
  height: 40px; 
  background-color: transparent; 
  border: 2px solid black; 
  border-radius: 5px; 
  cursor: pointer; 
  display: inline-flex; 
  align-items: center; 
  justify-content: center; 
  margin-right: 10px; /* spacing between the button and the next element */
}



.card-toggle-button {
  background-color: white;
  border: 1px solid lightgray;
  margin-left: 8px;  /* Add left margin to separate buttons */
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-toggle-button:hover {
  background-color: #f0f0f0;  /* Light gray background on hover */
}

.left-group {
  display: flex;
  align-items: center;
  width: 30%;
}

.raised-icon {
  transform: scale(1.2);  /* Scales the icon */
  position: relative;
  top: -1px; /* Raises the icon inside the button */
}

.config-count {
  margin-right: -10px;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}


.config-actions {
  display: flex;
  align-items: center; /* To vertically center align the child elements */
}

.config-count {
  margin-right: 10px; /* Adjust this to control the spacing between the number and the button */
  /* ... other styles ... */
}

.refresh-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem; /* Adjust as needed */
  margin-right: 0px; /* Adjust for spacing */
  outline: none;
  transition: color 0.3s;

  /* Optional: add some hover effect */
  &:hover {
      color: #007BFF; /* Or any desired color */
  }
}

/* CSS for the comp-container */
.comp-container {
  width: 100%; /* Ensure it spans the entire width of its parent */
  display: flex; /* Flex display so the child takes up full width */
  justify-content: center; /* Center the child element */
}








/* Filter Button */
.filter-button {
  background: linear-gradient(45deg, #4caf50, #81c784); /* Green gradient */
  color: #fff; /* White text */
  padding: 8px 16px; /* Spacing */
  border: none; /* Remove any default borders */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer; /* Hand cursor */
  transition: box-shadow 0.3s, transform 0.3s; /* Smooth transition */
  display: inline-block;
  font-size: 14px;
  margin-left: 20px;
  font-weight: bold; /* Maintain bold font weight */
  position: relative; /* Maintain relative positioning */
}

.filter-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly pronounced shadow on hover */
  transform: translateY(-2px); /* Slight upward motion for feedback */
}

.filter-button:active {
  transform: translateY(0); /* Reset position when active/clicked */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Reset shadow when active/clicked */
}



/* Filter Modal */
.filter-modal {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 10px;
  position: relative;
  right: 15%;
  margin-left: 230px;
  width: 600px; /* Adjust as needed */
  height: 50px;
  max-height: 50px;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Spread out elements */
  align-items: flex-end; /* Centers items vertically */
}

/* Style for the container of each status option */
.filter-modal div {
  display: flex;
  align-items: flex-end; /* Centers items vertically */
  justify-content: space-between; /* Spreads items evenly */
  margin-bottom: 5px; /* Space between each option */
}

/* Style for the labels of each checkbox */
.filter-modal label {
  margin-left: 10px; /* Space between checkbox and label */
  font-weight: normal; /* Bold text */
  color: #333; /* Darker text color */
  font-size: 15px; /* Font size */
  
}

/* Base styles for the modal and labels */
.filter-modal label {
  cursor: pointer;
  position: relative;
  padding-left: 25px; /* Space for custom checkbox */
}

/* Hide the default checkbox */
.filter-modal input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  position: absolute;
  margin: 0;
}

/* Custom checkbox styling */
.filter-modal input[type='checkbox'] + label:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 15px;
  height: 15px;
  border: 2px solid #4caf50;
  border-radius: 3px;
  cursor: pointer;
}

/* Change the appearance on hover */
.filter-modal input[type='checkbox']:hover + label:before,
.filter-modal input[type='checkbox']:checked:hover + label:before {
  background-color: #c5f3c7; /* Lighter green for hover */
}

/* Style when checkbox is checked */
.filter-modal input[type='checkbox']:checked + label:before {
  background-color: #4caf50;
  border-color: #4caf50;
}

.filter-modal input[type='checkbox']:checked + label:after {
  content: ''; /* Unicode checkmark */
  position: absolute;
  top: -2px; /* Adjust as needed */
  left: 4px; /* Adjust as needed */
  color: white;
  font-size: 14px;
}


/* Adjust focus style for checkboxes */
.filter-modal input[type='checkbox']:focus + label:before {
  outline: 2px solid #c5f3c7; /* Adds a focus outline */
  outline-offset: 2px; /* Distance of outline from the element */
}

/* Adjustments for hover state on checked checkboxes to change color */
.filter-modal input[type='checkbox']:checked:hover + label:before {
  background-color: #367b3a; /* Darker green for checked + hover */
}

/* Style for the apply button and its hover and active states */
.filter-modal-apply {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.filter-modal-apply:hover {
  background-color: #388e3c;
  transform: scale(1.05);
}

.filter-modal-apply:active {
  transform: scale(0.95);
}

.comprehensive-metrics-container .chart-and-stats {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between the chart and stats */
}

.comprehensive-metrics-container .metric-content, .comprehensive-metrics-container .chart-stats {
  flex: 1;
}








.welcome-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.welcome-card {
  background: linear-gradient(45deg, #4caf50, #81c784); /* Green gradient */
  color: #fff;
  padding: 40px; /* Increase padding for taller cards */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  flex: 1 1 calc(30% - 40px); /* Flexible width */
  max-width: calc(30% - 40px); /* Maximum width */
  min-height: 150px; /* Minimum height for taller cards */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-card h2 {
  margin: 0 0 10px 0;
  font-family: 'Roboto'; /* Use the Roboto font */
  font-size: 24px; /* Increase font size */
  font-weight: 700; /* Bold font */
}

.welcome-card p {
  margin: 0;
  font-family: 'Roboto'; /* Use the Roboto font */
  font-size: 18px; /* Increase font size */
  font-weight: 400; /* Regular font weight */
}

.welcome-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


.data-menu-container {
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch; 
  width: 100%;
  padding: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
}

.comprehensive-metrics, .breakcounts-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #fff;
  min-height: 250px;
}

.metrics-data-container, .breakcounts-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
}

.comprehensive-metrics h2, .breakcounts-container h2 {
  font-size: 20px;
}

.comprehensive-metrics p, .breakcounts-container p {
  font-size: 16px;
}

.data-charts, .comprehensive-metrics, .breakcounts-container {
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.chart-comprehensive-metrics {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center-align child elements horizontally */
}

.chart-container {
  width: 220px;   /* Adjust as needed */
  height: 220px;  /* Adjust as needed */
  position: relative;
  margin: 20px 0; /* Optional: Adding some vertical margin for spacing between the title and chart */
}

.chart-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center-align child elements horizontally */
  flex: 1; /* This will allow each section to take up equal width */
}

.chart-comprehensive-metrics {
  display: flex;
  justify-content: space-between; /* This will separate the two chart sections */
  width: 100%;
}









