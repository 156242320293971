:root {
    --primary-color: #149b2a;
    --secondary-color: #2c3e50;
    --bg-color: #ecf0f1;
    --highlight-color: #6de73c;
    --darktestbg:#04222e;
}

body, html {
    background-color: var(--bg-color);
}

.header {
    background-color: var(--bg-color);
}

h1 {
    color: var(--primary-color);
}

.config-card {
    border: 1px solid #ffffff;
    padding: 15px;
    margin: 10px;
    background-color: #fcfcfc;
    border-radius: 8px;
    align-self: flex-start;
    width: calc(33% - 20px);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    min-width: 350px;
    vertical-align: top;
    min-height: 170px;
}

.config-card:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Slightly pronounced shadow when focused */
  transform: scale(1.01); /* Slight growth for feedback */
  }



.config-card-button:hover {
    background-color: #053f03;
}

.config-card-button.active {
    background-color: #00552c;
}

.config-card-checkmarks {
    display: flex;
    justify-content: left;
    gap: 10px;
    margin-bottom: 10px;
    padding-top: 15px; /* Add top padding here */
    position: absolute;
    bottom: 10px; /* adjust based on your needs */
    right: 10px;
}

.config-card-button {
    background-color: var(--primary-color);
    transition: background-color 0.3s;
}

.config-card-button:hover {
    background-color: var(--highlight-color);
}

.config-card-edit-button {
    background-color: var(--primary-color);
    transition: background-color 0.3s;
}

.config-card-edit-button:hover {
 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly pronounced shadow on hover */
    transform: translateY(-2px); /* Slight upward motion for feedback */
}

.config-filters button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s;
}

.config-filters button:hover {
    background-color: var(--primary-color);
}


.config-card-header {
    display: flex;
    justify-content: space-between;
    align-items: start; /* Align items to the top */
}
.config-card-action-button {
    background-color: rgb(255, 255, 255); /* Making the background transparent */
    color: black; /* Setting the text color to black */
    border: 1px solid black; /* Adding a border */
    border-radius: 5px;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Added color transition */
    margin-top: 0px;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    position: absolute;
    top: 10px; /* adjust based on your needs */
    right: 10px;

    /* Optional: add a hover effect to show interactivity */
    &:hover {
        background-color: rgb(255, 255, 255); /* On hover, the background becomes black */
        color:#ff0707; /* And the text becomes white */
    }
}


.config-card {
    /* common styles */
    border-radius: 5px;
    transition: background-color 0.3s;
    text-decoration: none; /* Removes the underline from the Link */
    color: black; /* Makes the text black */
    position: relative;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, Geneva, sans-serif;
}

.config-card.whitesh-green {
    background-color: #E6FFE6; /* light green */
    border: 2px solid #00FF00; /* green */
}

.config-card.whitesh-red {
    background-color: #FFE6E6; /* light red */
    border: 2px solid #ffacac; /* red */
}

.config-card.white {
    background-color: #f1f1f1; /* light red */
 
}

.config-card.whitesh-yellow {
    background-color: #f5ffbe; /* light red */
    border: 2px solid #edf04a; /* red */
}

.config-card-data {
    position: absolute; /* This will take the container out of the normal flow */
    bottom: 10px; /* Place it 10px above the bottom border */
    left: 10px; /* Start it 10px from the left border */
    right: 10px; /* End it 10px before the right border */
    display: flex;
    align-items: center;
    justify-content: flex-start;  /* Align items to start on the left */
    gap: 10px;  /* Gap between items */
}

.config-data-item {
    display: flex;
    align-items: center;  /* Vertically align icon with text */
    gap: 5px;  /* Gap between icon and its text */
}

.config-card-title {
    font-weight: bold;
    font-size: 1.25em; /* Adjust size as needed */
    margin: 0; /* To remove any default margins */
}



.config-card-button {
    width: 100%;
    text-align: center;
    padding: 6px;
    border-radius: 20px;
    background-color: rgb(0, 49, 0);
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.config-card-type {
    font-size: 0.9em; /* Smaller font size than the status message */
    color: #666; /* Subdued color for less emphasis */
    margin-top: 5px; /* Spacing between status message and type */
  }
  
  .loading-indicator {
    display: inline-block;
    margin-left: 10px;
    font-weight: bold;
    color: #007BFF; /* Bootstrap primary color, you can use any color */
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
}

.config-card .loading-data {
    text-align: center;
    color: #6c757d; /* Bootstrap's secondary text color */
    font-size: 16px;
    padding: 10px;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

