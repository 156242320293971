/* ConfigurationTab Styles */
.config-tab-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

.config-tab-container h2 {
    color: #333;
    margin-bottom: 20px;
    font-size: 1.8em;
}

.config-tab-data-item {
    background-color: #fdfdfd;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    position: relative; /* Added for the line */
}

.config-tab-data-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
    width: 2px;
    background-color: #4CAF50; /* Color of the line */
    border-radius: 1px;
}

.config-tab-data-item h3 {
    font-size: 1.4em;
    color: #333;
    margin: 0;
    flex-basis: 30%;
    padding-right: 20px;
    border-right: 1px solid #ddd;
}

.config-tab-data-item p {
    font-size: 1em;
    color: #666;
    margin: 0;
    flex-basis: 70%;
    padding-left: 20px;
}

.accordion-title {
    font-size: 1.4em;
    color: #333;
    margin-top: 20px;
}

.config-column-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.config-column-item:last-child {
    border-bottom: none;
}

.config-column-item h4 {
    font-size: 1.2em;
    margin-bottom: 5px;
}

.config-column-item p {
    font-size: 1em;
    color: #666;
    margin: 0;
}

.edit-config-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    display: block;
    width: 200px;
    margin: 20px auto 0;
    text-align: center;
}

.edit-config-button:hover {
    background-color: #45a049;
}
.config-accordion .accordion {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.config-accordion .accordion-title {
    background-color: #f7f7f7;
    color: #333;
    padding: 15px 20px;
    margin: 0;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.config-accordion .accordion-title:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01); /* Adding shadow effect */
}

.config-accordion .accordion-content {
    padding: 20px;
    background-color: #fff;
}

.config-accordion .config-column-item {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fafafa;
    border: 1px solid #eaeaea;
    border-radius: 4px;
}

.config-accordion .column-item-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}

.config-accordion button {
    background-color: white;
    color: #4CAF50;
    border: 2px solid #4CAF50;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.config-accordion button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow effect */
    
}


.config-accordion input[type="text"] {
    padding: 10px;
    margin: 5px 0 10px 0;
    
    border-radius: 4px;
    width: calc(100% - 22px);
    display: block;
}

.config-accordion .ignore-characters-section, .config-accordion .mapping-section {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
}

.config-accordion .ignore-character-item, .config-accordion .mapping-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.config-accordion .ignore-character-item input, .config-accordion .mapping-item input {
    flex-grow: 1;
    margin-right: 10px;
}




/* ConfigurationTab.css */
.column-item-title {
    font-size: 1.2em;
    font-weight: bold;
}

.column-item-data-type {
    font-size: 0.8em;
    color: #999; /* Light gray color to make it faded */
    margin-left: 8px; /* Small margin to separate it from the title */
}

.number-thresholds-section,
.date-thresholds-section,
.rounding-section {
    margin-top: 10px;
}

.number-threshold-item,
.date-threshold-item,
.rounding-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.number-threshold-item label,
.date-threshold-item label,
.rounding-item label {
    margin-right: 10px;
}

.number-threshold-item input,
.date-threshold-item input,
.rounding-item select {
    margin-right: 10px;
    padding: 5px;
}


.config-accordion .rounding-section {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.config-accordion .rounding-item {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 10px;
}

.config-accordion .rounding-item label {
    margin-right: 10px;
    flex: 0 0 auto;
}

.config-accordion .rounding-item select {
    flex-grow: 1;
    padding: 10px; /* Double the height */
    border: 1px solid #000; /* Black fill border with 4px width */
    border-radius: 4px;
    background-color: #fff; /* Ensure the background is white */
}

.config-accordion .text-transformation-section {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.config-accordion .substring-extraction-section {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.config-accordion .substring-extraction-section label,
.config-accordion .substring-extraction-section input {
    margin-right: 10px;
}

.config-accordion .text-transformation-section label,
.config-accordion .text-transformation-section select {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

.config-accordion .number-transformation-section {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.config-accordion .number-transformation-section label,
.config-accordion .number-transformation-section input {
    margin-right: 10px;
}

.config-accordion .number-transformation-section label {
    flex: 0 0 auto;
}

.config-accordion .number-transformation-section input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}


.config-accordion .date-transformation-section {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.config-accordion .date-transformation-section label,
.config-accordion .date-transformation-section input,
.config-accordion .date-transformation-section select {
    margin-right: 10px;
}

.config-accordion .date-transformation-section label {
    flex: 0 0 auto;
}

.config-accordion .date-transformation-section input,
.config-accordion .date-transformation-section select {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

/* AggregationSection Styles */
.aggregation-section {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.aggregation-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.aggregation-item label {
    flex: 0 0 150px;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
}

.aggregation-item select {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}


/* Improved dropdown styles */
.custom-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
}

.custom-dropdown select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    appearance: none;
    cursor: pointer;
}

.custom-dropdown::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: #666;
}

/* Ensure the entire bar is clickable */
.accordion-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.3s;
}



.accordion-title h3 {
    margin: 0;
    flex-grow: 1;
}

.transform-data-title {
    text-align: center;
  }
  