/* CSS for the ComprehensiveMetricsDisplay and its children */
.comprehensive-metrics-display {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Take up the full width of comp-container */
    font-family: 'Roboto', sans-serif; /* Use the Roboto font */
  }
  
  .metric-column {
    flex: 1;
    margin: 0 10px; /* Added some horizontal margin for spacing between columns */
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    border-radius: 5px;
    max-height: 275px; /* Set a fixed height or use min-height if you want it to be flexible */
    overflow-y: auto; /* To scroll if content inside becomes too much */
  }
  
  .metric-title {
    font-size: 18px; /* Smaller title size */
    margin-bottom: 20px; /* Some spacing to separate title from content */
    font-weight: 500; /* Medium weight for the title */
    color: #333; /* Dark color for better visibility */
  }
  
  .metric-content {
    font-size: 16px; /* Slightly bigger font size for content */
  }
  
  .metric-content p {
    margin: 10px 0; /* Some spacing between each metric item */
    font-weight: 400; /* Regular weight for content */
  }
  